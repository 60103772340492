const axios = require("axios");

const { URL_API, URL_API_V2 } = require("../config.js");
import { LOCAL_STORAGE_KEYS } from "../constants/index.js";
const urlBase = URL_API;

// 'Content-Type': 'application/json',
// 'Content-Type': 'multipart/form-data',
// 'Content-Type': 'application/x-www-form-encoded',

/**
 * @param {string} $url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser así, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = "") =>
  url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `${urlBase}${url}`;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  // USUARIO Y CONTRASE;A
};

// Authorization Token
const getToken = () => {
  return {
    token: sessionStorage.getItem(LOCAL_STORAGE_KEYS.accessToken),
  };
};

const get = (url = "", options = {}) =>
  axios.get(readUrl(url), {
    withCredentials: true,
    headers: {
      ...headers,
      ...options.headers,
    },
    ...options,
  });

const apiGet = (url = "", options = {}) => get(`${URL_API}${url}`, options);

const apiGetV2 = (url = "", options = {}) => {
  const { token } = getToken();
  return get(`${URL_API_V2}${url}`, {
    ...options,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...options.headers,
    },
  });
};

const post = (url = "", body = {}, options = {}) =>
  axios.post(readUrl(url), body, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      ...options.headers,
    },
    ...options,
  });

const apiPost = (url = "", body = {}, options = {}) =>
  post(readUrl(url), body, options);

const apiPostV2 = (url = "", body = {}, options = {}) => {
  const { token } = getToken();

  return post(`${URL_API_V2}${url}`, body, {
    ...options,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...options.headers,
    },
  });
};

const put = (url = "", body = {}, options = {}) =>
  axios.put(readUrl(url), body, {
    withCredentials: true,
    headers: {
      ...headers,
      ...options.headers,
    },
    ...options,
  });

const del = (url = "", headers = {}) =>
  axios.delete(readUrl(url), {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });

export default {
  apiPost,
  apiGet,
  apiPostV2,
  get,
  post,
  put,
  apiGetV2,
  delete: del,
};
