import React from "react";
import cx from "classnames";

import style from "./serverError500.css";

const ServerError500 = () => {
  return (
    <div className={cx(style.contentError500)}>
      <img src="/img/errors/error500.svg" />

      <p className={cx(style.text500)}>500: Error interno del servidor</p>

      <p className={cx(style.textInfo)}>
        O intentaste alguna ruta incorrecta o a ocurrido un error inesperado.
        Sea lo que sea, intenta utilizar la navegación.
      </p>

      <button className={cx("btn")}>
        <a href="/">Volver al inicio</a>
      </button>
    </div>
  );
};

export default ServerError500;
