import React, { Component } from 'react';
import ServerError500 from './errors/ServerError500';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
		console.error('getDerivedStateFromError =>', error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// También puedes registrar el error en un servicio de reporte de errores
		// logErrorToMyService(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <ServerError500/>
		}

		return this.props.children; 
	}
}

export default ErrorBoundary;